<template>
  <div>
    <div class="buttons justify-content-start mt-2">
      <download-excel
        class="button primary sm"
        :data="users"
        :fields="fieldsExport"
        name="Utilizadores"
      >
        <b-icon
          class="mr-2"
          scale="1"
          icon="file-earmark-excel-fill"
        />Exportar
        para excel
      </download-excel>
    </div>
    <b-row class="mb-4">
      <b-col class="title">
        Utilizadores registados
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-input-group
          size="sm"
          class="mb-3"
        >
          <template #prepend>
            <b-input-group-text>
              <b-icon
                icon="search"
                scale="0.8"
              />
            </b-input-group-text>
          </template>
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Pesquisar..."
          />

          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Limpar
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-table
          v-if="users"
          id="users"
          :items="users"
          :per-page="perPage"
          :current-page="currentPage"
          :fields="fields"
          :filter="filter"
          :filter-included-fields="filterOn"
          borderless
          hover
          small
        >
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <b-form-checkbox
                :checked="rowSelected"
                value="true"
              />
            </template>
            <template v-else>
              <b-form-checkbox :checked="rowSelected" />
            </template>
          </template>
          <template #cell(actions)="row">
            <div class="buttons m-0 p-0 justify-content-start buttons-group">
              <div
                class="button primary xs"
                @click="
                  () => {
                    newEmail = null;
                    row.toggleDetails();
                  }
                "
              >
                <b-icon
                  scale="1.2"
                  :icon="row.detailsShowing ? 'eye-slash' : 'eye'"
                />
              </div>
            </div>
          </template>
          <template #row-details="row">
            <b-card>
              <div
                style=" align-items: flex-start; display: flex; flex-direction: column;"
              >
                <div style="order: 1;">
                  <div style="display: flex; flex-direction: row;">
                    <b class="mr-1">Permissões:</b>{{ permissions[row.item["permission_id"] - 1] }}
                    <div class="buttons p-0 m-0 justify-content-start ml-4">
                      <div
                        v-if="row.item['id'] !== $store.getters.user.id"
                        class="button outlined no-border secondary sm"
                        style="text-decoration: underline;"
                        @click="
                          () => {
                            newPermissionsUser = row.item['id'];
                            $bvModal.show('representUser');
                          }
                        "
                      >
                        {{
                          row.item["permission_id"] !== 1 &&
                            row.item["id"] !== $store.getters.user.id
                            ? "Alterar para administrador"
                            : null
                        }}
                        {{
                          row.item["permission_id"] === 1 &&
                            row.item["id"] !== $store.getters.user.id
                            ? "Alterar para utilizador"
                            : null
                        }}
                      </div>
                      <div
                        v-if="row.item['id'] !== $store.getters.user.id"
                        class="button outlined no-border secondary sm"
                        style="text-decoration: underline;"
                        @click="
                          () => {
                            newConsortiumUser = row.item['id'];
                            newConsortiumValue = !!!row.item['consortium'];
                            $bvModal.show('consortium');
                          }
                        "
                      >
                        {{
                          row.item["consortium"] === 0 &&
                            row.item["id"] !== $store.getters.user.id
                            ? "Definir perfil de consórcio"
                            : null
                        }}

                        {{
                          row.item["consortium"] === 1 &&
                            row.item["id"] !== $store.getters.user.id
                            ? "Remover perfil de consórcio"
                            : null
                        }}
                      </div>
                    </div>
                  </div>
                  <div style="order: 2;">
                    <b>Empresa:</b>
                    {{
                      companies.filter(
                        (element) => element.id === row.item["company_id"]
                      )[0].name
                    }}
                  </div>
                  <div style="order: 3;">
                    <b>Registado em:</b>
                    {{ row.item["created_at"].split(" ")[0] }}
                  </div>
                  <div class="d-flex flex-row my-2 align-items-center">
                    <b-form-group
                      id="input-group-4"
                      class="m-0 mr-4 "
                      label-for="input-4"
                    >
                      <b-form-input
                        id="input-4"
                        :value="row.item['email']"
                        type="email"
                        required
                        placeholder="Email do respondente"
                        @input="
                          (value) => {
                            newEmail = value;
                          }
                        "
                      />
                    </b-form-group>
                    <div class="buttons my-0">
                      <div
                        class="button sm primary"
                        @click="
                          () => {
                            updateUser(row.item['id']);
                          }
                        "
                      >
                        Alterar
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="users"
        />
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col class="title">
        Empresas registadas
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-input-group
          size="sm"
          class="mb-3"
        >
          <template #prepend>
            <b-input-group-text>
              <b-icon
                icon="search"
                scale="0.8"
              />
            </b-input-group-text>
          </template>
          <b-form-input
            id="filter-input-companies"
            v-model="filterCompanies"
            type="search"
            placeholder="Pesquisar..."
          />

          <b-input-group-append>
            <b-button
              :disabled="!filterCompanies"
              @click="filterCompanies = ''"
            >
              Limpar
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-table
          id="companies"
          :items="companies"
          :per-page="perPage"
          :current-page="currentPageCompanies"
          :fields="fieldsCompanies"
          :filter="filterCompanies"
          :filter-included-fields="filterOnCompanies"
          borderless
          hover
          small
        >
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <b-form-checkbox
                :checked="rowSelected"
                value="true"
              />
            </template>
            <template v-else>
              <b-form-checkbox :checked="rowSelected" />
            </template>
          </template>
          <template #cell(actions)="row">
            <div class="buttons m-0 p-0 justify-content-start buttons-group">
              <div
                class="button primary xs"
                @click="row.toggleDetails"
              >
                <b-icon
                  scale="1.2"
                  :icon="row.detailsShowing ? 'eye-slash' : 'eye'"
                />
              </div>
            </div>
          </template>
          <template #row-details="row">
            <b-card>
              <div
                style=" align-items: flex-start; display: flex; flex-direction: column;"
              >
                <div v-if="regions[row.item['region']]">
                  <b>Região:</b> {{ regions[row.item["region"]] }}
                </div>
                <div v-if="row.item['country']">
                  <b>País:</b> {{ row.item["country"] }}
                </div>
                <div
                  v-if="industries[row.item['industry']]"
                  style="order: 1;"
                >
                  <b>Indústria:</b> {{ industries[row.item["industry"]].text }}
                </div>
                <div
                  v-if="marks_1[row.item['employees']]"
                  style="order: 2;"
                >
                  <b>Nº de empregados:</b> {{ marks_1[row.item["employees"]] }}
                </div>
                <div
                  v-if="marks_2[row.item['turnover']]"
                  style="order: 3;"
                >
                  <b>Volume de negócios:</b> {{ marks_2[row.item["turnover"]] }}
                </div>
                <div
                  v-if="concept[row.item['know_the_concept']]"
                  style="order: 4;"
                >
                  <b>Conhece o conceito de i4.0:</b>
                  {{ concept[row.item["know_the_concept"]] }}
                </div>
                <div
                  v-if="row.item['causes'] && row.item['causes'].length > 0"
                  style="order: 5;"
                  class="text-left"
                >
                  <b>O que motiva:</b>
                  <div
                    v-for="cause in JSON.parse(row.item['causes'])"
                    :key="`${row.item['id']}_causes_${cause}`"
                  >
                    - {{ causes[cause] }}
                  </div>
                </div>
                <div
                  v-if="row.item['goals'] && row.item['goals'].length > 0"
                  style="order: 6;"
                  class="text-left"
                >
                  <b>Objetivos a atingir:</b>
                  <div
                    v-for="goal in JSON.parse(row.item['goals'])"
                    :key="`${row.item['id']}_goals_${goal}`"
                  >
                    - {{ goals[goal] }}
                  </div>
                </div>
                <div
                  v-if="row.item['status']"
                  style="order: 7;"
                  class="text-left"
                >
                  <b>Atividades:</b>
                  <div>
                    - Digitalização de processos com a implementação de
                    informação: {{ labels[JSON.parse(row.item["status"])[0]] }}
                  </div>
                  <div>
                    - Integração entre sistemas e/ou equipamentos com recurso à
                    IoT (Internet das coisas):
                    {{ labels[JSON.parse(row.item["status"])[1]] }}
                  </div>
                  <div>
                    - Implementação de sistemas que permitam o controlo
                    eficiente dos processos, produtos e serviços e a análise do
                    desempenho em tempo real:
                    {{ labels[JSON.parse(row.item["status"])[2]] }}
                  </div>
                  <div>
                    - Contratação de técnicos essenciais para a transformação
                    digital: {{ labels[JSON.parse(row.item["status"])[3]] }}
                  </div>
                  <div>
                    - Contratação de consultores para a transformação digital:
                    {{ labels[JSON.parse(row.item["status"])[4]] }}
                  </div>
                  <div>
                    - Reconversão de técnicos para dar resposta à transformação
                    digital: {{ labels[JSON.parse(row.item["status"])[5]] }}
                  </div>
                </div>
                <div
                  v-if="economic_sector[row.item['economic_sector']]"
                  style="order: 8;"
                  class="open-sans-regular"
                >
                  <b>Setor económico:</b>
                  {{ economic_sector[row.item["economic_sector"]] }}
                </div>
                <div
                  v-if="row.item['clusters']"
                  style="order: 9;"
                  class="open-sans-regular"
                >
                  <b>Associações empresariais, industriais ou clusters: </b>
                  {{ row.item["clusters"] }}
                </div>
              </div>
            </b-card>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPageCompanies"
          :total-rows="rowsCompanies"
          :per-page="perPageCompanies"
          aria-controls="users"
        />
      </b-col>
    </b-row>
    <b-modal
      id="consortium"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      centered
      hide-footer
      size="lg"
    >
      <div>
        <div class="title">
          Alterar perfil de consórcio
        </div>

        <div class="text-dark text-center">
          Tem a certeza que pretende alterar o perfil de consórcio deste utilizador?
        </div>

        <div class="buttons">
          <button
            class="button secondary"
            type="submit"
            @click="
              () => {
                newConsortiumUser = null;
                newConsortiumValue= null;
                $bvModal.hide('representUser');
              }
            "
          >
            Cancelar
          </button>
          <button
            class="button primary"
            type="submit"
            @click="switchConsortium"
          >
            Alterar
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="representUser"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      centered
      hide-footer
      size="lg"
    >
      <div>
        <div class="title">
          Alterar permissões
        </div>

        <div class="text-dark text-center">
          Tem a certeza que pretende alterar as permissões deste utilizador?
        </div>

        <div class="buttons">
          <button
            class="button secondary"
            type="submit"
            @click="
              () => {
                newPermissionsUser = null;
                $bvModal.hide('representUser');
              }
            "
          >
            Cancelar
          </button>
          <button
            class="button primary"
            type="submit"
            @click="switchPermissions"
          >
            Alterar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { get, post, put } from '../../../services/api';

export default {
  name: 'GeneralTab',
  props: {
    setLoading: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      users: null,
      companies: null,
      filter: null,
      filterCompanies: null,
      filterOn: [],
      filterOnCompanies: [],
      currentPage: 1,
      currentPageCompanies: 1,
      selectedUsers: [],
      selectedCompanies: [],
      perPage: 10,
      perPageCompanies: 10,
      newPermissionsUser: null,
      newConsortiumUser: null,
      newConsortiumValue: null,
      fieldsExport: {
        'ID da empresa': {
          callback: (value) => this.companies.find((el) => el.id === value.company_id).id,
        },
        'Nome da empresa': {
          callback: (value) => this.companies.find((el) => el.id === value.company_id).name,
        },
        'NIF da empresa': {
          callback: (value) => this.companies.find((el) => el.id === value.company_id).vatID,
        },
        'Data de registo da empresa': {
          callback: (value) => {
            const date = new Date(
              this.companies.find((el) => el.id === value.company_id).created_at,
            );
            return [
              date.getDate().toString().length < 2
                ? `0${date.getDate()}`
                : date.getDate(),
              (date.getMonth() + 1).toString().length < 2
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1,
              date.getFullYear(),
            ].join('/');
          },
        },
        'Hora de registo da empresa': {
          callback: (value) => {
            const date = new Date(
              this.companies.find((el) => el.id === value.company_id).created_at,
            );
            return [
              date.getHours().toString().length < 2
                ? `0${date.getHours()}`
                : date.getHours(),
              date.getMinutes().toString().length < 2
                ? `0${date.getMinutes()}`
                : date.getMinutes(),
            ].join(':');
          },
        },
        '': {
          callback: () => '',
        },
        'ID do utilizador': 'id',
        Tipo: {
          field: 'permission_id',
          callback: (value) => this.permissions[value - 1],
        },
        'Nome do utilizador': 'name',
        'Email do utilizador': 'email',
        'Data de registo do utilizador': {
          field: 'created_at',
          callback: (value) => {
            const date = new Date(value);
            return [
              date.getDate().toString().length < 2
                ? `0${date.getDate()}`
                : date.getDate(),
              (date.getMonth() + 1).toString().length < 2
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1,
              date.getFullYear(),
            ].join('/');
          },
        },
        'Hora de registo do utilizador': {
          field: 'created_at',
          callback: (value) => {
            const date = new Date(value);
            return [
              date.getHours().toString().length < 2
                ? `0${date.getHours()}`
                : date.getHours(),
              date.getMinutes().toString().length < 2
                ? `0${date.getMinutes()}`
                : date.getMinutes(),
            ].join(':');
          },
        },
        /*  Estado: {
          field: 'status',
          callback: (value) => this.status[value].label,
        }, */
      },
      fields: [
        {
          key: 'name',
          sortable: true,
          label: 'Nome',
          thStyle: { width: '45%' },
        },
        {
          key: 'email',
          sortable: true,
          label: 'Email',
          thStyle: { width: '45%' },
        },
        { key: 'actions', label: 'Ações', thStyle: { width: '10%' } },
      ],
      fieldsCompanies: [
        {
          key: 'name',
          sortable: true,
          label: 'Nome',
          thStyle: { width: '45%' },
        },
        {
          key: 'vatID',
          sortable: true,
          label: 'NIF',
          thStyle: { width: '45%' },
        },
        { key: 'actions', label: 'Ações', thStyle: { width: '10%' } },
      ],
      labels: {
        1: 'Não planeada',
        2: 'Planeada',
        3: 'Em curso',
        4: 'Implementada',
        5: 'Não relevante',
      },
      status: {
        open: { icon: 'check-circle', color: 'green', label: 'Ativo' },
        submitted: { icon: 'x-circle', color: 'blue', label: 'Fechado' },
        rejected: { icon: 'bookmark-x', color: 'red', label: 'Rejeitado' },
        approved: { icon: 'bookmark-check', color: 'green', label: 'Aprovado' },
        default: { icon: 'list-check', color: 'green', label: '' },
      },
      industries: {
        1: {
          text: 'Agricultura, produção animal, caça, floresta e pesca',
          icon: 'tractor',
        },
        2: {
          text: 'Indústrias extractivas',
          icon: 'hard-hat',
        },
        3: {
          text: 'Indústrias transformadoras',
          icon: 'industry',
        },
        4: {
          text: 'Electricidade, gás, vapor, água quente e fria e ar frio',
          icon: 'charging-station',
        },
        5: {
          text:
            'Captação, tratamento e distribuição de água; saneamento, gestão de resíduos e despoluição',
          icon: 'faucet',
        },
        6: {
          text: 'Construção',
          icon: 'hammer',
        },
        7: {
          text:
            'Comércio por grosso e a retalho; reparação de veículos automóveis e motociclos',
          icon: 'wrench',
        },
        8: {
          text: 'Transportes e armazenagem',
          icon: 'truck',
        },
        9: {
          text: 'Alojamento, restauração e similares',
          icon: 'hotel',
        },
        10: {
          text: 'Actividades de informação e de comunicação',
          icon: 'tty',
        },
        11: {
          text: 'Actividades financeiras e de seguros',
          icon: 'piggy-bank',
        },
        12: {
          text: 'Actividades imobiliárias',
          icon: 'home',
        },
        13: {
          text: 'Actividades de consultoria, científicas, técnicas e similares',
          icon: 'flask',
        },
        14: {
          text: 'Actividades administrativas e dos serviços de apoio',
          icon: 'hands-helping',
        },
        15: {
          text: 'Administração Pública e Defesa; Segurança Social Obrigatória',
          icon: 'shield-alt',
        },
        16: {
          text: 'Educação',
          icon: 'university',
        },
        17: {
          text: 'Actividades de saúde humana e apoio social',
          icon: 'heartbeat',
        },
        18: {
          text:
            'Actividades artísticas, de espectáculos, desportivas e recreativas',
          icon: 'paint-brush',
        },
        19: {
          text: 'Outras actividades de serviços',
          icon: 'taxi',
        },
        20: {
          text:
            'Actividades das famílias empregadoras de pessoal doméstico e actividades de produção das famílias para uso próprio',
          icon: 'users',
        },
        21: {
          text:
            'Actividades dos organismos internacionais e outras instituições extra-territoriais',
          icon: 'globe',
        },
      },
      regions: {
        1: 'Alto Minho',
        2: 'Cávado',
        3: 'Ave',
        4: 'Área Metropolitana do Porto',
        5: 'Alto Tâmega',
        6: 'Tâmega e Sousa',
        7: 'Douro',
        8: 'Terras de Trás-os-Montes',
        9: 'Oeste',
        10: 'Região de Aveiro',
        11: 'Região de Coimbra',
        12: 'Região de Leiria',
        13: 'Viseu Dão Lafões',
        14: 'Beira Baixa',
        15: 'Médio Tejo',
        16: 'Beiras e Serra da Estrela',
        17: 'Área Metroplotinada de Lisboa',
        18: 'Alentejo Litoral',
        19: 'Baixo Alentejo',
        20: 'Lezíria do Tejo',
        21: 'Alto Alentejo',
        22: 'Alentejo Central',
        23: 'Algarve',
        24: 'Região Autónoma dos Açores',
        25: 'Região Autónoma da Madeira',
      },
      permissions: [
        'Administrador',
        'Sem permissões especiais',
        'Gestor da empresa',
        'Facilitador',
      ],
      marks_1: {
        1: 'Até 9',
        2: 'De 10 a 49',
        3: 'De 50 a 249',
        4: '250 ou mais',
      },
      marks_2: {
        0: 'Abaixo de 2 milhões de euros',
        1: 'Entre 2 e 10 milhões de euros',
        2: 'Entre 10 e 50 milhões de euros',
        3: 'Acima de 50 milhões de euros',
        4: 'Não especificado',
      },
      concept: {
        1: 'Não, nunca ouvi falar',
        2: 'Vagamente e não é importante para a empresa',
        3: 'Superficialmente e pretendo saber mais para avaliar o seu potencial na empresa',
        4: 'O suficiente para já termos alguns projetos piloto a decorrer',
        5: 'Dominamos o conceito e já o aplicamos de forma transversal na empresa',
      },
      causes: {
        1: 'Oportunidade para se diferenciar no mercado',
        2: 'A empresa tem espírito inovador, pelo que tem de experimentar novos conceitos',
        3: 'Faz parte da sua condição de líder de mercado',
        4: 'Os requisitos de mercado e a pressão competitiva',
      },
      goals: {
        1: 'Aumento da eficiência das operações ou serviços',
        2: 'Aumento dos proveitos',
        3: 'Aumento da eficiência do sistema de gestão',
      },
      economic_sector: {
        1: 'Agricultura, produção animal, caça e actividades dos serviços relacionados',
        2: 'Silvicultura e exploração florestal',
        3: 'Pesca e aquicultura',
        5: 'Extracção de hulha e lenhite',
        6: 'Extracção de petróleo bruto e gás natural',
        7: 'Extracção e preparação de minérios metálicos',
        8: 'Outras indústrias extractivas',
        9: 'Actividades dos serviços relacionados com as indústrias extractivas',
        10: 'Indústrias alimentares',
        11: 'Indústria das bebidas',
        12: 'Indústria do tabaco',
        13: 'Fabricação de têxteis',
        14: 'Indústria do vestuário',
        15: 'Indústria do couro e dos produtos do couro',
        16: 'Indústrias da madeira e da cortiça e suas obras, excepto mobiliário; fabricação de obras de cestaria e de espartaria',
        17: 'Fabricação de pasta, de papel, cartão e seus artigos',
        18: 'Impressão e reprodução de suportes gravados',
        19: 'Fabricação de coque, de produtos petrolíferos refinados e de aglomerados de combustíveis',
        20: 'Fabricação de produtos químicos e de fibras sintéticas ou artificiais, excepto produtos farmacêuticos',
        21: 'Fabricação de produtos farmacêuticos de base e de preparações farmacêuticas',
        22: 'Fabricação de artigos de borracha e de matérias plásticas',
        23: 'Fabricação de outros produtos minerais não metálicos',
        24: 'Indústrias metalúrgicas de base',
        25: 'Fabricação de produtos metálicos, excepto máquinas e equipamentos',
        26: 'Fabricação de equipamentos informáticos, equipamento para comunicações e produtos electrónicos e ópticos',
        27: 'Fabricação de equipamento eléctrico',
        28: 'Fabricação de máquinas e de equipamentos, n.e.',
        29: 'Fabricação de veículos automóveis, reboques, semi-reboques e componentes para veículos automóveis',
        30: 'Fabricação de outro equipamento de transporte',
        31: 'Fabricação de mobiliário e de colchões',
        32: 'Outras indústrias transformadoras',
        33: 'Reparação, manutenção e instalação de máquinas e equipamentos',
        35: 'Electricidade, gás, vapor, água quente e fria e ar frio',
        36: 'Captação, tratamento e distribuição de água',
        37: 'Recolha, drenagem e tratamento de águas residuais',
        38: 'Recolha, tratamento e eliminação de resíduos; valorização de materiais',
        39: 'Descontaminação e actividades similares',
        41: 'Promoção imobiliária (desenvolvimento de projectos de edifícios); construção de edifícios',
        42: 'Engenharia civil',
        43: 'Actividades especializadas de construção',
        45: 'Comércio, manutenção e reparação, de veículos automóveis e motociclos',
        46: 'Comércio por grosso (inclui agentes), excepto de veículos automóveis e motociclos',
        47: 'Comércio a retalho, excepto de veículos automóveis e motociclos',
        49: 'Transportes terrestres e transportes por oleodutos ou gasodutos',
        50: 'Transportes por água',
        51: 'Transportes aéreos',
        52: 'Armazenagem e actividades auxiliares dos transportes(inclui manuseamento)',
        53: 'Actividades postais e de courier',
        55: 'Alojamento',
        56: 'Restauração e similares',
        58: 'Actividades de edição',
        59: 'Actividades cinematográficas, de vídeo, de produção de programas de televisão, de gravação de som e de edição de música',
        60: 'Actividades de rádio e de televisão',
        61: 'Telecomunicações',
        62: 'Consultoria e programação informática e actividades relacionadas',
        63: 'Actividades dos serviços de informação',
        64: 'Actividades de serviços financeiros, excepto seguros e fundos de pensões',
        65: 'Seguros, resseguros e fundos de pensões, excepto segurança social obrigatória',
        66: 'Actividades auxiliares de serviços financeiros e dos seguros',
        68: 'Actividades imobiliárias',
        69: 'Actividades jurídicas e de contabilidade',
        70: 'Actividades das sedes sociais e de consultoria para a gestão',
        71: 'Actividades de arquitectura, de engenharia e técnicas afins; actividades de ensaios e de análises técnicas',
        72: 'Actividades de Investigação científica e de desenvolvimento',
        73: 'Publicidade, estudos de mercado e sondagens de opinião',
        74: 'Outras actividades de consultoria, científicas, técnicas e similares',
        75: 'Actividades veterinárias',
        77: 'Actividades de aluguer',
        78: 'Actividades de emprego',
        79: 'Agências de viagem, operadores turísticos, outros serviços de reservas e actividades relacionadas',
        80: 'Actividades de investigação e segurança',
        81: 'Actividades relacionadas com edifícios, plantação e manutenção de jardins',
        82: 'Actividades de serviços administrativos e de apoio prestados às empresas',
        84: 'Administração Pública e Defesa; Segurança Social Obrigatória',
        85: 'Educação',
        86: 'Actividades de saúde humana',
        87: 'Actividades de apoio social com alojamento',
        88: 'Actividades de apoio social sem alojamento',
        90: 'Actividades de teatro, de música, de dança e outras actividades artísticas e literárias',
        91: 'Actividades das bibliotecas, arquivos, museus e outras actividades culturais',
        92: 'Lotarias e outros jogos de aposta',
        93: 'Actividades desportivas, de diversão e recreativas',
        94: 'Actividades das organizações associativas',
        95: 'Reparação de computadores e de bens de uso pessoal e doméstico',
        96: 'Outras actividades de serviços pessoais',
        97: 'Actividades das famílias empregadoras de pessoal doméstico',
        98: 'Actividades de produção de bens e serviços pelas famílias para uso próprio',
        99: 'Actividades dos organismos internacionais e outras instituições extraterritoriais',
      },
      newEmail: null,
    };
  },
  computed: {
    rows() {
      if (this.users) {
        return this.users.length;
      }
      return 0;
    },
    rowsCompanies() {
      if (this.companies) {
        return this.companies.length;
      }
      return 0;
    },
  },
  async created() {
    this.setLoading(true);
    await this.getCompanies();
    await this.getUsers();
    this.setLoading(false);
  },
  methods: {
    onRowSelectedUsers(items) {
      this.selectedUsers = items;
    },
    onRowSelectedCompanies(items) {
      this.selectedCompanies = items;
    },
    async updateUser(id) {
      await put(`user/${id}`, { email: this.newEmail }).then(async (response) => {
        await this.getUsers();
      });
    },
    async getCompanies() {
      await get('company/').then((response) => {
        this.companies = response.data.companies;
      });
    },
    async getUsers() {
      await get('user/').then((response) => {
        this.users = response.data.users;
      });
    },
    async switchConsortium() {
      await put(`user/${this.newConsortiumUser}`, {
        consortium: this.newConsortiumValue,
      }).then(() => {
        this.$bvModal.hide('consortium');
        this.success = true;
      });
      await this.getUsers();
    },
    async switchPermissions() {
      await post('switch-admin', {
        user: this.newPermissionsUser,
      }).then(() => {
        this.$bvModal.hide('representUser');

        this.success = true;
      });
      await this.getUsers();
    },
  },
};
</script>
